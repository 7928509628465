import { IconButton } from "@mui/material";
import { unixToDateWithFormat } from "../../../utils/date-converter";
import {
  formatNumber,
  formatNumberWithValidate,
  formatPriceNumber,
} from "../../../utils/dataTransformer";
import {
  dateComparator,
  filterParamsOptions,
  filterStatusValueFormatter,
} from "../../../utils/filterparams";
import CustomizedAvatar from "../../Custom/CustomizedAvatar";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import CloseIcon from "@mui/icons-material/Close";
import InventoryService from "../../../services/Inventory";

export const deliveryOrderColumnDefs = (
  t,
  referenceDeleteHandler,
  supressSortAndFitler,
  viewOnly,
  isDeliveryTrip,
  isReport
) => {
  if (isReport) {
    return [
      {
        field: "document_id",
        headerName: t("logistic.documentId"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 250,
      },
      {
        field: "issue_date",
        headerName: t("logistic.issueDate"),
        filter: "agDateColumnFilter",
        width: 175,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
        valueFormatter: (params) =>
          typeof params.value === "number"
            ? unixToDateWithFormat(params.value)
            : params.value,
      },
      {
        field: "delivery_date",
        headerName: t("logistic.deliveryDate"),
        filter: "agDateColumnFilter",
        width: 175,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
        valueFormatter: (params) =>
          typeof params.value === "number"
            ? unixToDateWithFormat(params.value)
            : params.value,
      },
      {
        field: "render_status",
        headerName: t("sales.status"),
        sortable: false,
        filter: "agSetColumnFilter",
        filterParams: {
          valueFormatter: (params) => {
            return filterStatusValueFormatter(params.value);
          },
          values: ["ร่าง", "รอจัดส่ง", "สำเร็จ", "ไม่สำเร็จ", "ยกเลิก"],
        },
        cellRenderer: (params) => {
          return <CustomizedStatus status={params.value} />;
        },
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
      },
      {
        field: "contact_document_id",
        headerName: t("logistic.customerId"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        hide: true,
        width: 175,
      },
      {
        field: "contact_name",
        headerName: t("logistic.customerName"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        width: 175,
      },
      {
        field: "item_group_level_1_name_list",
        headerName: "หมวดหมู่สินค้า",
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params) => {
            const data = await InventoryService.getAllItemLevel1();
            const itemLevel1 = data.map((warehouse) => warehouse.name);
            params.success(itemLevel1);
          },
        },
        valueFormatter: (params) => {
          if (params.value) {
            return params.value.name;
          } else {
            return "";
          }
        },
      },
      {
        field: "price_vat_type",
        headerName: t("reports.price_vat_type"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        hide: true,
      },
      {
        field: "item_document_id",
        headerName: t("reports.item_document_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "item_name",
        headerName: t("reports.item_name"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "qty",
        headerName: t("reports.qty"),
        filter: false,
        valueFormatter: (params) => formatNumberWithValidate(params.value),
      },
      {
        field: "uom_name",
        headerName: t("reports.uom"),
        filter: false,
      },
      {
        field: "price_per_unit",
        headerName: t("reports.price_per_unit"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
        valueGetter: (params) => formatPriceNumber(params.data.price_per_unit),
      },
      {
        field: "vat_type",
        headerName: t("reports.vat_type"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "pre_vat_amount_exclude_discount",
        headerName: t("reports.pre_vat_amount_exclude_discount"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
        valueFormatter: (params) => formatNumberWithValidate(params.value),
      },
      {
        field: "item_discount",
        headerName: t("reports.item_discount"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
        valueFormatter: (params) => formatNumberWithValidate(params.value),
      },
      {
        field: "item_additional_discount",
        headerName: t("reports.item_additional_discount"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
        valueFormatter: (params) => formatNumberWithValidate(params.value),
      },
      {
        field: "pre_vat_amount_include_discount",
        headerName: t("reports.pre_vat_amount_include_discount"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
        valueFormatter: (params) => formatNumberWithValidate(params.value),
      },
      {
        field: "item_vat_exempted_amount",
        headerName: t("reports.item_vat_exempted_amount"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
        valueFormatter: (params) => formatNumberWithValidate(params.value),
      },
      {
        field: "item_vat_0_amount",
        headerName: t("reports.item_vat_0_amount"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
        valueFormatter: (params) => formatNumberWithValidate(params.value),
      },
      {
        field: "item_vat_7_amount",
        headerName: t("reports.item_vat_7_amount"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
        valueFormatter: (params) => formatNumberWithValidate(params.value),
      },
      {
        field: "item_vat_amount",
        headerName: t("reports.item_vat_amount"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
        valueFormatter: (params) => formatNumberWithValidate(params.value),
      },
      {
        field: "item_net_amount",
        headerName: t("reports.item_net_amount"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
        valueFormatter: (params) => formatNumberWithValidate(params.value),
      },
      {
        field: "item_withholding_tax_type",
        headerName: t("reports.item_withholding_tax_type"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "item_withholding_tax",
        headerName: t("reports.item_withholding_tax"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
        valueFormatter: (params) => formatNumberWithValidate(params.value),
      },
      {
        field: "item_total_amount",
        headerName: t("reports.item_total_amount"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
        valueFormatter: (params) => formatNumberWithValidate(params.value),
      },
      {
        field: "shipping_cost",
        headerName: t("reports.shipping_cost"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
        valueFormatter: (params) => formatNumberWithValidate(params.value),
      },
      {
        field: "total_amount",
        headerName: t("reports.total_amount"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
        valueFormatter: (params) => formatNumberWithValidate(params.value),
      },
      {
        field: "additional_discount",
        headerName: t("reports.additional_discount"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
        valueFormatter: (params) => formatNumberWithValidate(params.value),
      },
      {
        field: "remark",
        headerName: t("reports.remark"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "reference_document_id",
        headerName: t("reports.reference_document_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        hide: true,
      },
      {
        field: "external_ref_id",
        headerName: t("reports.external_ref_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        hide: true,
      },
      {
        field: "item_description",
        headerName: t("reports.item_description"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "item_remark",
        headerName: t("reports.item_remark"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "created_date",
        headerName: t("reports.created_date"),
        filter: "agDateColumnFilter",
        width: 175,
        sort: "desc",
        hide: true,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
        },
        valueFormatter: (params) => unixToDateWithFormat(params.value),
      },
      {
        field: "updated_date",
        headerName: t("reports.updated_date"),
        filter: "agDateColumnFilter",
        width: 175,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "created_by",
        headerName: t("reports.created_by"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        hide: true,
        sortable: false,
      },
      {
        field: "employee_list",
        headerName: t("sales.employeeList"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        hide: true,
        sortable: false,
      },
      {
        field: "email",
        headerName: t("reports.email"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "phone",
        headerName: t("reports.phone"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "fax",
        headerName: t("reports.fax"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "address_contact_name",
        headerName: t("reports.delivery.contact_name"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "address_contact_phone",
        headerName: t("reports.delivery.phone"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "delivery_address",
        headerName: t("reports.delivery_address"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        hide: true,
      },
      {
        field: "delivery_sub_district",
        headerName: t("reports.delivery_sub_district"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        hide: true,
      },
      {
        field: "delivery_district",
        headerName: t("reports.delivery_district"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "delivery_province",
        headerName: t("reports.delivery_province"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "delivery_postal_code",
        headerName: t("reports.delivery_postal_code"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        hide: true,
      },
      {
        field: "delivery_country",
        headerName: t("reports.delivery_country"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        hide: true,
      },
      {
        field: "recipient_name",
        headerName: t("reports.delivery.recipient_name"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "delivery_reference",
        headerName: t("reports.delivery.delivery_reference"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "internal_remark",
        headerName: t("logistic.internalRemark"),
        filter: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
    ];
  }

  const formatColumnDef = [
    {
      field: "document_id",
      headerName: t("logistic.documentId"),
      filter: supressSortAndFitler ? false : "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      sortable: supressSortAndFitler ? false : true,
      width: 250,
    },
    {
      field: "issue_date",
      headerName: t("logistic.issueDate"),
      filter: supressSortAndFitler ? false : "agDateColumnFilter",
      width: isDeliveryTrip ? 250 : 175,
      sortable: supressSortAndFitler ? false : true,
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => unixToDateWithFormat(params.value),
      hide: true,
    },
    {
      field: "delivery_date",
      headerName: t("logistic.deliveryDate"),
      filter: supressSortAndFitler ? false : "agDateColumnFilter",
      width: 175,
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => unixToDateWithFormat(params.value),
      sortable: supressSortAndFitler ? false : true,
    },
    {
      field: "render_status",
      headerName: t("sales.status"),
      sortable: false,
      filter: supressSortAndFitler ? false : "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          return filterStatusValueFormatter(params.value);
        },
        values: [
          "draft",
          "waitDeliver",
          "completed",
          "notCompleted",
          "cancelled",
        ],
      },
      cellRenderer: (params) => {
        return <CustomizedStatus status={params.value} />;
      },
      cellStyle: {
        display: "flex",
        justifycontent: "center",
        alignItems: "center",
      },
    },
    {
      field: "contact_document_id",
      headerName: t("logistic.customerId"),
      filter: supressSortAndFitler ? false : "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      sortable: supressSortAndFitler ? false : true,
      valueGetter: (params) => params?.data?.contact?.document_id,
      width: 250,
    },
    {
      field: "contact",
      headerName: t("logistic.customerName"),
      filter: supressSortAndFitler ? false : "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      valueGetter: (params) =>
        `${params?.data?.contact?.contact_name_1 ?? ""} ${
          params?.data?.contact?.contact_name_2 ?? ""
        }`,
      sortable: false,
      resizable: true,
    },
    {
      field: "created_date",
      headerName: t("logistic.createdDate"),
      filter: false,
      width: 175,
      sort: supressSortAndFitler ? false : "desc",
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "total_amount",
      headerName: t("logistic.deliveryOrder.totalAmount"),
      width: 225,
      filter: supressSortAndFitler ? false : "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("number"),
      },
      valueFormatter: (params) => formatNumber(params.value),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },

    {
      field: "dt_document_id_list",
      headerName: "หมายเลขอ้างอิง DT",
      filter: supressSortAndFitler ? false : "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      sortable: supressSortAndFitler ? false : true,
      width: 250,
      hide: supressSortAndFitler ? true : false,
    },
    {
      field: "reference_document_id",
      headerName: "หมายเลขอ้างอิง SO",
      filter: supressSortAndFitler ? false : "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      sortable: supressSortAndFitler ? false : true,
      width: 250,
    },
    {
      field: "item_group_level_1_name_list",
      headerName: "หมวดหมู่สินค้า",
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params) => {
          const data = await InventoryService.getAllItemLevel1();
          const itemLevel1 = data.map((warehouse) => warehouse.name);
          params.success(itemLevel1);
        },
      },
      valueFormatter: (params) => {
        if (params.value) {
          return params.value.name;
        } else {
          return "";
        }
      },
    },
    {
      field: "address",
      headerName: t("logistic.deliveryAddress"),
      filter: supressSortAndFitler ? false : "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      hide: isDeliveryTrip ? false : true,
      valueGetter: (params) =>
        params?.data.customer?.delivery_address?.address ?? "",
      sortable: false,
    },
    {
      field: "sub_district",
      headerName: t("logistic.deliverySubDistrict"),
      filter: supressSortAndFitler ? false : "agTextColumnFilter",
      hide: isDeliveryTrip ? false : true,
      valueGetter: (params) =>
        params?.data.customer?.delivery_address?.sub_district ?? "",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      sortable: false,
    },
    {
      field: "district",
      headerName: t("logistic.deliveryDistrict"),
      filter: supressSortAndFitler ? false : "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      hide: isDeliveryTrip ? false : true,
      valueGetter: (params) =>
        params?.data.customer?.delivery_address?.district ?? "",
      sortable: false,
    },
    {
      field: "province",
      headerName: t("logistic.deliveryProvince"),
      filter: supressSortAndFitler ? false : "agTextColumnFilter",
      valueGetter: (params) =>
        params?.data.customer?.delivery_address?.province || "",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 150,
      sortable: supressSortAndFitler ? false : "desc",
    },
    {
      field: "postal_code",
      headerName: t("logistic.deliveryPostalCode"),
      filter: supressSortAndFitler ? false : "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      hide: isDeliveryTrip ? false : true,
      valueGetter: (params) =>
        params?.data.customer?.delivery_address?.postal_code ?? "",
      sortable: false,
    },
    {
      field: "remark",
      headerName: t("remark"),
      filter: supressSortAndFitler ? false : "agTextColumnFilter",
      hide: isDeliveryTrip ? false : true,
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      valueGetter: (params) => params?.data.remark,
      sortable: false,
    },
    {
      field: "employee_list",
      headerName: t("sales.employeeList"),
      filter: supressSortAndFitler ? false : "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      cellRenderer: (params) => {
        return <CustomizedAvatar avatars={params.value} />;
      },
      sortable: false,
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
  ];

  if (referenceDeleteHandler && !viewOnly) {
    formatColumnDef.push({
      field: "",
      cellRenderer: (params) => {
        return (
          <IconButton
            aria-label="delete"
            size="small"
            sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
            onClick={() => referenceDeleteHandler(params.node.rowIndex)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        );
      },
      width: 80,
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    });
  }

  return formatColumnDef;
};
