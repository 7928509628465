export const COMPANY_NAME = "NPR Digital Partner";

export const PREVIOUS_VERSION = "3.4.1";
export const CURRENT_VERSION = "3.4.2";

export let isServiceDown = false;

export const setIsServiceDown = (isDown) => {
  isServiceDown = isDown;
};
